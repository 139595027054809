exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-articles-page-js": () => import("./../../../src/templates/articles-page.js" /* webpackChunkName: "component---src-templates-articles-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-post-page-js": () => import("./../../../src/templates/post-page.js" /* webpackChunkName: "component---src-templates-post-page-js" */),
  "component---src-templates-review-guide-page-js": () => import("./../../../src/templates/review-guide-page.js" /* webpackChunkName: "component---src-templates-review-guide-page-js" */),
  "component---src-templates-review-page-js": () => import("./../../../src/templates/review-page.js" /* webpackChunkName: "component---src-templates-review-page-js" */),
  "component---src-templates-reviews-page-js": () => import("./../../../src/templates/reviews-page.js" /* webpackChunkName: "component---src-templates-reviews-page-js" */)
}

